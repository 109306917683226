import * as React from "react"
// import { Link } from "gatsby"


import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Press" />
    <div className="contentholder-central">
    <h1>Press Pack for the Grand Guess Hotel</h1>
    <p><a href='https://e.pcloud.link/publink/show?code=XZauh5ZWHVjK2o0tYmvXWT4OaVyCRDeURhk'>Please download the press pack here</a>.</p>
    </div>
  </Layout>
)

export default IndexPage
